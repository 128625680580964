require('./bootstrap');

require('./app/toggle-password');

require('./app/select-all');

require('./app/select-multiple');

import MCDatepicker from '../../node_modules/mc-datepicker/dist/mc-calendar.min.js';

import Swal from 'sweetalert2';

window.deleteConfirm = function(formId)
{
    Swal.fire({
        icon: 'warning',
        text: 'Weet je zeker dat je de reservering wil verwijderen?',
        showCancelButton: true,
        cancelButtonText: 'Annuleer',
        confirmButtonText: 'Verwijder',
        confirmButtonColor: '#e3342f',
    }).then((result) => {
        if (result.isConfirmed) {
            document.getElementById(formId).submit();
        }
    });
}

const defaultTheme = {
    theme_color: '#3cb64a',
    main_background: '#3cb64a',
    display: {
        background: '#3cb64a'
    },
    picker: {
        background: '#f5f5f6'
    },
    picker_header: {
        active: '#000',
        inactive: 'rgba(0, 0, 0, 0.2)'
    },
    weekday: {
        foreground: '#3cb64a'
    },
    button: {
        success: {
            foreground: '#3cb64a'
        },
        danger: {
            foreground: '#e65151'
        }
    },
    date: {
        active: {
            default: {
                foreground: 'rgb(0, 0, 0)'
            },
            picked: {
                foreground: '#ffffff',
                background: '#3cb64a'
            },
            today: {
                foreground: 'rgb(0, 0, 0)',
                background: 'rgba(0, 0, 0, 0.2)'
            }
        },
        inactive: {
            default: {
                foreground: '#e65151'
            },
        },
    },
    month_year_preview: {
        active: {
            default: {
                foreground: 'rgb(0, 0, 0)'
            },
            picked: {
                foreground: 'rgb(0, 0, 0)',
                background: 'rgba(0, 0, 0,0.2)'
            }
        },
        inactive: {
            default: {
                foreground: 'rgba(0, 0, 0, 0.2)'
            },
            picked: {
                foreground: 'rgba(0, 0, 0, 0.2)',
                background: 'rgba(0, 0, 0, 0.2)'
            }
        }
    }
};

const picker = MCDatepicker.create({
    el: '#datepicker',
    dateFormat: 'DD-MM-YYYY',
    firstWeekday: 1,
    disableWeekends: false,
    closeOnBlur: true,
    closeOndblclick: false,
    customClearBTN: 'Leegmaken',
    customCancelBTN: 'Annuleren',
    customOkBTN: 'Bevestig',
    customWeekDays: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
    customMonths: [
        'Januari',
        'Februari',
        'Maart',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Augustus',
        'September',
        'Oktober',
        'November',
        'December'
    ],
    theme: defaultTheme,
});

(function () {
    document.querySelector('#openDate')?.addEventListener('click', function (element) {
        picker.open();
    });
})();

document.querySelector('.mc-display__date').style.color = "#fff";
document.querySelector('.mc-display__day').style.color = "#fff";
document.querySelector('.mc-display__month').style.color = "#fff";
document.querySelector('.mc-display__year').style.color = "#fff";

document.querySelector('#mc-btn__ok').addEventListener('click', function() {
    if (!window.location.href.includes('?date=') && !window.location.href.includes('?q=')) {
        window.location = window.location + "?date=" + picker.getFormatedDate();
    } else if (window.location.href.includes('#F')) {
        window.location = window.location.href.split('#')[0] + "?date=" + picker.getFormatedDate();
    } else if (window.location.href.includes('?q=')) {
        window.location = window.location.href.split('?q=')[0] + "?date=" + picker.getFormatedDate();
    }
});

window.search = function (input, tableId)
{
    var filter, table, tr, td, i, txtValue;
    filter = input.target.value.toUpperCase();
    table = document.querySelector(tableId);
    tr = table.getElementsByTagName("tr");

    for (i = 0; i < tr.length; i++) {
        var tds = tr[i].getElementsByTagName("td");
        for (let j = 0; j < tr[i].getElementsByTagName("td").length; j++) {
            td = tds[j];
            if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                    break;
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    }
}